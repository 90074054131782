<template>
  <div >
    <v-container
      fill-height
      fluid
     
      v-if="result.message"
    >
      <v-row align="center" justify="center">
        <v-col sm="8" md="4">
          <v-alert :type="result.infoType">
            {{ result.message }}
          </v-alert>
        </v-col>
      </v-row>
    </v-container>
  </div>
  <!-- 
  v-if="typeof result.statusInfo!='undefined'"
    {if result.statusInfo=="register"}
  
    v-else-if="result.statusInfo=="active""
      {include "templates/templatesNewUser/activateInfoBox.tpl"}
    v-else-if="result.statusInfo=="support""
      {include "templates/templatesNewUser/support.tpl"}
    {else}

    {/if}
  {else}
    {* Nothing to Do *}
  {/if}
  -->

  <!-- </div> -->
</template>
<script>
export default {
  props: ["result"],
  data() {
    return {
      window: {
        width: 0,
        height: 0,
      },
    };
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight - 136;
    },
  },
};
</script>